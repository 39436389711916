body {
  margin: 0;
  font-family: "Twemoji Country Flags", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p1 {
  margin-top: 0.25em;
  margin-bottom: 0.25em;
}

p2 {
  margin-bottom: 1.25em;
}

p3 {
  margin-top: 0.25em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.grid-container {
  width: 30%;
  display: inline-block
}


.extra-container {
  width: 100%;
  max-width: 100%;
}

.info-container {
  height: 100%;

}

.until-lg {
  display: none;
}

@media (min-width: 992px) {
  .extra-container {
    max-width: 550px;
  }

  .info-container {
    height: fit-content;
  }

  .until-lg {
    display: block;
  }
}

@media (min-width:992px) {
  .hr-sect {
    display: flex;
    flex-basis: 100%;
    align-items: center;
    color: rgba(0, 0, 0, 0.35);
    font-size: 15px;
    margin: 8px 0px;
  }

  .hr-sect::before,
  .hr-sect::after {
    content: "";
    flex-grow: 1;
    background: rgba(0, 0, 0, 0.35);
    height: 1px;
    font-size: 0px;
    line-height: 0px;
    margin: 0px 16px;
  }
}

@media (max-width: 991px) {
  .filter-label {
    display: none;
  }
}

.guide-list {
  list-style-type: decimal;
  list-style-position: outside  ;
  padding-left: 2em;
  text-indent: 0em;
  /* Add any other custom settings here */
}